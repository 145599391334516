<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">手机号: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.phone"
                  placeholder="输入手机号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="11"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">发放时间: </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">发放结果: </span>
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.result"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="成功"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="失败"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
              >查询</el-button>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
              >重置</el-button>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
                class="marginLeft10 marginBottom10"
              >导出</el-button>
            </div>

          </div>

        </div>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import dayjs from "dayjs";
import { couponDetailsList } from "@/api/coupon/send";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        phone: "",
        result: "",
        // createTime: [],
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "发放详情",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "手机号",
            prop: "phone",
          },
          {
            label: "用户昵称",
            prop: "nickname",
          },
          {
            label: "发放时间",
            prop: "createTime",
          },
          {
            label: "发放结果",
            prop: "result",
            formatter: function (row, column, cellValue) {
              return row.result == 1 ? "成功" : row.result == 2 ? "失败" : "";
            },
          },
          {
            label: "失败原因",
            prop: "reason",
            formatter: function (row, column, cellValue) {
              return row.reason  ? row.reason  : "/";
            },
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
    };
  },
  // created() {
  //   const searchForm = JSON.parse(localStorage.getItem("searchForm"));
  //   if (searchForm?.path === this.$route.path) {
  //     this.searchData = searchForm.searchData;
  //   } else {
  //     localStorage.removeItem("searchForm");
  //   }
  // },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        result: this.searchData.result,
        phone: this.searchData.phone,
        // payChannel: this.searchData.payChannel,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData: {
          ...this.searchData,
        },
      };

      if (this.searchData.createTime) {
        searchForm.searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchForm.searchData.createTimeEnd = dayjs(
          this.searchData.createTime[1]
        ).format("YYYY-MM-DD 23:59:59");
      }

      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      couponDetailsList({
        id: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        result: "",
        phone: "",
        // payChannel: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },

    handleExport() {
      const searchData = {
        result: this.searchData.result,
        phone: this.searchData.phone,
        id:this.$route.query.id
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/coupon/send/detail/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
</style>