import { get, post, put, Delete } from "@/api/axios";

// 获取优惠劵列表
export const list = params => get('/web/coupon/send/list', params);

// 添加优惠劵
export const add = params => post('/web/coupon/send/add', params);

//选择优惠劵列表
export const getCouponList = params => get('/web/coupon/send/coupon/list', params)

//优惠劵详情列表
export const couponDetailsList = params => get('/web/coupon/send/detail/list', params)